import dynamic from 'next/dynamic'
import {ComponentFactoryConstants} from '../adapters/helpers/Constants';

const RichTextLink= dynamic(()=> import( './RichTextLink/RichTextLink'));
const RichText= dynamic(()=> import( './RichText/RichText'));
const MarkdownText= dynamic(()=> import( './MarkdownText/MarkdownText'));
const Zone= dynamic(()=> import( './Zone/Zone'));
const Layout= dynamic(()=> import( './Layout/Layout'));
const Content= dynamic(()=> import( './Content/Content'));
const ContentVideo= dynamic(()=> import( './ContentVideo/ContentVideo.js'));
const MainMenu= dynamic(()=> import( './MainMenu/MainMenu'));
const Footer= dynamic(()=> import( './Footer/Footer'));
const LanguageSelection= dynamic(()=> import( './LanguageSelection/LanguageSelection'));
const TabbedContent= dynamic(()=> import( './TabbedContent/TabbedContent'));
const AnnouncementBar= dynamic(()=> import( './AnnouncementBar/AnnouncementBar'));
const SideBySideContainer= dynamic(()=> import( './SideBySideContainer/SideBySideContainer'));
const SeriesSelector= dynamic(()=> import( './SeriesSelector/SeriesSelector'));
const MiniNavigation= dynamic(()=> import( './MiniNavigation/MiniNavigation'));
const PromoBlock= dynamic(()=> import( './Promo/Promo'));
const SpotlightSlider= dynamic(()=> import( './SpotlightSlider/SpotlightSlider'));
const SpotlightContentVideo= dynamic(()=> import( './SpotlightContentVideo/SpotlightContentVideo'));
const SpotlightContentBlock= dynamic(()=> import( './SpotlightContentBlock/SpotlightContentBlock'));
const SpotlightStickyBar= dynamic(()=> import( './SpotlightStickyBar/SpotlightStickyBar'));
const InTheBoxBlock= dynamic(()=> import( './InTheBoxBlock/InTheBoxBlock'));
const ProductGridBlock= dynamic(()=> import( './ProductGridBlock/ProductGridBlock'));
const CollectionTabsBlock= dynamic(()=> import( './CollectionTabs/CollectionTabsBlock'));
const ProductHighlights= dynamic(()=> import( './ProductHighlights/ProductHighlights'));
const FeaturesTabBlock= dynamic(()=> import( './FeaturesTabBlock/FeaturesTabBlock'));
const BrandGuaranteeBlock= dynamic(()=> import( './BrandGuaranteeBlock/BrandGuaranteeBlock'));
const GalleryBlock= dynamic(()=> import( './GalleryBlock/GalleryBlock'));
const FAQBlock= dynamic(()=> import( './FAQBlock/FAQBlock'));
const BrushConfigurator= dynamic(()=> import( './BrushConfigurator/BrushConfigurator'));
const ComparisonChartBlock= dynamic(()=> import( './ComparisonChartBlock/ComparisonChartBlock'));
const SpotlightWaitlistBlock= dynamic(()=> import( './SpotlightWaitlistBlock/SpotlightWaitlistBlock'));
const SpotlightPreorderBlock= dynamic(()=> import( './SpotlightPreorderBlock/SpotlightPreorderBlock'));
const CollectionTabsDropdown= dynamic(()=> import( './CollectionTabs/CollectionTabsDropdownBlock'));
const ProductSubNav= dynamic(()=> import( './ProductSubNav/ProductSubNav'));
const CharacterBlock= dynamic(()=> import( './CharacterBlock/CharacterBlock'));
const ProductRecommenderBlock= dynamic(()=> import( './ProductRecommender/ProductRecommender'));
const ShowcaseBlock= dynamic(()=> import( './ShowcaseBlock/ShowcaseBlock'));
const ArticleHeroBlock= dynamic(()=> import( './ArticleHeroBlock/ArticleHeroBlock'));
const SkipNavigationBlock= dynamic(()=> import( './SkipNavigationBlock/SkipNavigationBlock'));
const AppDownloadBlock= dynamic(()=> import( './AppDownloadBlock/AppDownloadBlock'));
const ArticleCategoryHeroBlock= dynamic(()=> import( './ArticleCategoryHeroBlock/ArticleCategoryHeroBlock'));
const RecentlyViewedProductsBlock= dynamic(()=> import( './RecentlyViewedProductsBlock/RecentlyViewedProductsBlock'));
const AdditionalReadingBlock= dynamic(()=> import( './AdditionalReadingBlock/AdditionalReadingBlock'));
const UserQuoteBlock= dynamic(()=> import( './UserQuoteBlock/UserQuoteBlock'));
const ProductOverlay= dynamic(()=> import( './ProductOverlay/ProductOverlay'));
const LoginBlock= dynamic(()=> import( './LoginBlock/LoginBlock'));
const ForgotPasswordBlock= dynamic(()=> import( './ForgotPasswordBlock/ForgotPasswordBlock'));
const ResetPasswordBlock= dynamic(()=> import( './ResetPasswordBlock/ResetPasswordBlock'));
const RegisterProductBlock = dynamic(() => import('./MixtureProductRegistration/View/RegisterProductBlock/RegisterProductBlock'));
const ErrorBlock= dynamic(()=> import( './ErrorBlock/ErrorBlock'));
const ArticleBody= dynamic(()=> import( './ArticleBody/ArticleBody'));
const CloudinaryImage= dynamic(()=> import( './CloudinaryImage/CloudinaryImage'));
const YoutubeVideo= dynamic(()=> import( './YoutubeVideo/YoutubeVideo'));
const CreateAccountBlock= dynamic(()=> import( './CreateAccountBlock/CreateAccountBlock'));
const RegisterTypeCodeBlock = dynamic(() => import('./MixtureProductRegistration/View/RegisterTypeCodeBlock/RegisterTypeCodeBlock'));
const ResponsiveImage= dynamic(()=> import( './ResponsiveImage/ResponsiveImage'));
const ContactUsBlock= dynamic(()=> import( './ContactUsBlock/ContactUsBlock'));
const PypestreamChat= dynamic(()=> import( './PypestreamChat/PypestreamChat'));
const PressQuoteBlock= dynamic(()=> import( './PressQuoteBlock/PressQuoteBlock'));
const SearchComponent= dynamic(()=> import( './SearchBlock/SearchComponent'));
const ProfileBlock= dynamic(()=> import( './ProfileBlock/ProfileBlock'));
const CongratulationsBlock = dynamic(() => import('./MixtureProductRegistration/View/CongratulationsBlock/CongratulationsBlock.js'));
const ChangePasswordBlock= dynamic(()=> import( './ChangePasswordBlock/ChangePasswordBlock'));
const LoginNavigationBlock= dynamic(()=> import( './LoginNavigationBlock/LoginNavigationBlock'));
const DesignedByDentistBlock= dynamic(()=> import( './DesignByDentist/DesignedByDentist'));
const AwardsBlock= dynamic(()=> import( './AwardsBlock/AwardsBlock'));
const LegacyArticleHeroBlock= dynamic(()=> import( './LegacyArticleHeroBlock/LegacyArticleHeroBlock'));
const ContentDetailsBlock= dynamic(()=> import( './ContentDetailsBlock/ContentDetailsBlock'));
const ConsentBlock= dynamic(()=> import( './ConsentBlock/ConsentBlock'));
const ThreeColumnsUserQuoteBlock= dynamic(()=> import( './ThreeColumnsUserQuoteBlock/ThreeColumnsUserQuoteBlock'));
const ArticleShare= dynamic(()=> import( './ArticleShare/ArticleShare'));
const LegacyProductHighlightsBlock= dynamic(()=> import( './LegacyProductHighlightsBlock/LegacyProductHighlightsBlock'));
const ArticleListBlock= dynamic(()=> import( './ArticleList/ArticlelistBlock'));
const ArticleTableBlock= dynamic(()=> import( './ArticleTableBlock/ArticleTableBlock'));
const SpotlightContest= dynamic(()=> import( './SpotlightContestBlock/SpotlightContestBlock'));
const SpotlightContestThankYou= dynamic(()=> import( './SpotlightContestThankYouBlock/SpotlightContestThankYouBlock'));
const FAQDetailsBlock= dynamic(()=> import( './FaqDetailsBlock/FaqDetailsBlock'));
const ProductLegacyBody= dynamic(()=> import( './ProductLegacyBody/ProductLegacyBody'));
const Disclaimer= dynamic(()=> import( './Disclaimer/Disclaimer'));
const ProductVariantsRecommanderBlock= dynamic(()=> import( './ProductVariantsRecommanderBlock/ProductVariantsRecommanderBlock'));
const SitemapBlock= dynamic(()=> import( './SitemapBlock/SitemapBlock'));
const SitemapPage= dynamic(()=> import( './SitemapPage/SitemapPage'));
const HTMLBlock= dynamic(()=> import( './HTMLBlock/HTMLBlock'));
const SpotlightExperienceBlock= dynamic(()=> import( './SpotlightExperienceBlock/SpotlightExperienceBlock'));
const BreadCrumbs= dynamic(()=> import( './Breadcrumbs/Breadcrumbs'));
const DoubleOptInVerificationBlock= dynamic(()=> import( './DoubleOptInVerificationBlock/DoubleOptInVerificationBlock'));
const FAQSearchComponent= dynamic(()=> import( './FAQBlock/FAQSearchComponent'));
const CarouselBlock= dynamic(()=> import( './CarouselBlock/CarouselBlock'));
const DragSlider= dynamic(()=> import( './DragSlider/DragSlider'));
const AccordionBlock= dynamic(()=> import( './AccordionBlock/AccordionBlock'));
const SwitchTab= dynamic(()=> import( './SwitchTab/SwitchTab'));
const MiniContentBlock = dynamic(() => import('./MiniContentBlock/MiniContentBlock.js'));
const DentistContacts = dynamic(() => import('./DentistContacts/DentistContacts'));

class ComponentFactory {

  constructor() {
    this.modules = {};
    this.addModule(ComponentFactoryConstants.Zone, Zone);
    this.addModule(ComponentFactoryConstants.Layout, Layout);
    this.addModule(ComponentFactoryConstants.Content, Content);
    this.addModule(ComponentFactoryConstants.ContentVideo, ContentVideo);
    this.addModule(ComponentFactoryConstants.RichText, RichText);
    this.addModule(ComponentFactoryConstants.RichTextLink, RichTextLink);
    this.addModule(ComponentFactoryConstants.MarkdownText, MarkdownText);
    this.addModule(ComponentFactoryConstants.MainMenu, MainMenu);
    this.addModule(ComponentFactoryConstants.Footer, Footer);
    this.addModule(ComponentFactoryConstants.LanguageSelection, LanguageSelection);
    this.addModule(ComponentFactoryConstants.TabbedContent, TabbedContent);
    this.addModule(ComponentFactoryConstants.AnnouncementBar, AnnouncementBar);
    this.addModule(ComponentFactoryConstants.SideBySideContainer, SideBySideContainer);
    this.addModule(ComponentFactoryConstants.SeriesSelector, SeriesSelector);
    this.addModule(ComponentFactoryConstants.MiniNavigation, MiniNavigation);
    this.addModule(ComponentFactoryConstants.PromoBlock, PromoBlock);
    this.addModule(ComponentFactoryConstants.SpotlightSlider, SpotlightSlider);
    this.addModule(ComponentFactoryConstants.SpotlightContentVideo, SpotlightContentVideo);
    this.addModule(ComponentFactoryConstants.SpotlightContentBlock, SpotlightContentBlock);
    this.addModule(ComponentFactoryConstants.SpotlightStickyBar, SpotlightStickyBar);
    this.addModule(ComponentFactoryConstants.InTheBoxBlock, InTheBoxBlock);
    this.addModule(ComponentFactoryConstants.ProductGridBlock, ProductGridBlock);
    this.addModule(ComponentFactoryConstants.CollectionTabsBlock, CollectionTabsBlock);
    this.addModule(ComponentFactoryConstants.ProductHighlights, ProductHighlights);
    this.addModule(ComponentFactoryConstants.FeaturesTabBlock, FeaturesTabBlock)
    this.addModule(ComponentFactoryConstants.BrandGuaranteeBlock, BrandGuaranteeBlock);
    this.addModule(ComponentFactoryConstants.GalleryBlock, GalleryBlock);
    this.addModule(ComponentFactoryConstants.FAQBlock, FAQBlock);
    this.addModule(ComponentFactoryConstants.BrushConfigurator, BrushConfigurator);
    this.addModule(ComponentFactoryConstants.ComparisonChartBlock, ComparisonChartBlock);
    this.addModule(ComponentFactoryConstants.UserQuoteBlock, UserQuoteBlock);
    this.addModule(ComponentFactoryConstants.SpotlightWaitlistBlock, SpotlightWaitlistBlock);
    this.addModule(ComponentFactoryConstants.SpotlightPreorderBlock, SpotlightPreorderBlock);
    this.addModule(ComponentFactoryConstants.CollectionTabsDropdown, CollectionTabsDropdown);
    this.addModule(ComponentFactoryConstants.ProductSubNav, ProductSubNav);
    this.addModule(ComponentFactoryConstants.CharacterBlock, CharacterBlock);
    this.addModule(ComponentFactoryConstants.ProductRecommenderBlock, ProductRecommenderBlock);
    this.addModule(ComponentFactoryConstants.ShowcaseBlock, ShowcaseBlock);
    this.addModule(ComponentFactoryConstants.ArticleHeroBlock, ArticleHeroBlock);
    this.addModule(ComponentFactoryConstants.SkipNavigationBlock, SkipNavigationBlock);
    this.addModule(ComponentFactoryConstants.AppDownloadBlock, AppDownloadBlock);
    this.addModule(ComponentFactoryConstants.ArticleCategoryHeroBlock, ArticleCategoryHeroBlock);
    this.addModule(ComponentFactoryConstants.RecentlyViewedProductsBlock, RecentlyViewedProductsBlock);
    this.addModule(ComponentFactoryConstants.AdditionalReadingBlock, AdditionalReadingBlock);
    this.addModule(ComponentFactoryConstants.ProductOverlay, ProductOverlay);
    this.addModule(ComponentFactoryConstants.ErrorBlock, ErrorBlock);
    this.addModule(ComponentFactoryConstants.ArticleBody, ArticleBody);
    this.addModule(ComponentFactoryConstants.CloudinaryImage, CloudinaryImage);
    this.addModule(ComponentFactoryConstants.YoutubeVideo, YoutubeVideo);
    this.addModule(ComponentFactoryConstants.ResponsiveImage, ResponsiveImage);
    this.addModule(ComponentFactoryConstants.ContactUsBlock, ContactUsBlock);
    this.addModule(ComponentFactoryConstants.LoginBlock, LoginBlock);
    this.addModule(ComponentFactoryConstants.ForgotPasswordBlock, ForgotPasswordBlock);
    this.addModule(ComponentFactoryConstants.ResetPasswordBlock, ResetPasswordBlock);
    this.addModule(ComponentFactoryConstants.CreateAccountBlock, CreateAccountBlock);
    this.addModule(ComponentFactoryConstants.PypestreamChat, PypestreamChat);
    this.addModule(ComponentFactoryConstants.RegisterTypeCodeBlock, RegisterTypeCodeBlock);
    this.addModule(ComponentFactoryConstants.RegisterProductBlock, RegisterProductBlock);
    this.addModule(ComponentFactoryConstants.CongratulationsBlock, CongratulationsBlock);
    this.addModule(ComponentFactoryConstants.ProfileBlock, ProfileBlock);
    this.addModule(ComponentFactoryConstants.ChangePasswordBlock, ChangePasswordBlock);
    this.addModule(ComponentFactoryConstants.PressQuoteBlock, PressQuoteBlock);
    this.addModule(ComponentFactoryConstants.SearchBlock, SearchComponent);
    this.addModule(ComponentFactoryConstants.LoginNavigationBlock, LoginNavigationBlock);
    this.addModule(ComponentFactoryConstants.DesignedByDentist, DesignedByDentistBlock);
    this.addModule(ComponentFactoryConstants.Awards, AwardsBlock);
    this.addModule(ComponentFactoryConstants.LegacyArticleHeroBlock, LegacyArticleHeroBlock);
    this.addModule(ComponentFactoryConstants.ContentDetailsBlock, ContentDetailsBlock);
    this.addModule(ComponentFactoryConstants.ConsentBlock, ConsentBlock);
    this.addModule(ComponentFactoryConstants.ThreeColumnsUserQuoteBlock, ThreeColumnsUserQuoteBlock);
    this.addModule(ComponentFactoryConstants.ArticleShare, ArticleShare);
    this.addModule(ComponentFactoryConstants.LegacyProductHighlightBlock, LegacyProductHighlightsBlock);
    this.addModule(ComponentFactoryConstants.ArticleListBlock, ArticleListBlock);
    this.addModule(ComponentFactoryConstants.SpotlightContestBlock, SpotlightContest);
    this.addModule(ComponentFactoryConstants.SpotlightContestThankYouBlock, SpotlightContestThankYou);
    this.addModule(ComponentFactoryConstants.ArticleTableBlock, ArticleTableBlock);
    this.addModule(ComponentFactoryConstants.ProductLegacyBody, ProductLegacyBody);
    this.addModule(ComponentFactoryConstants.FaqDetailsBlock, FAQDetailsBlock);
    this.addModule(ComponentFactoryConstants.Disclaimer, Disclaimer);
    this.addModule(ComponentFactoryConstants.ProductVariantsRecommanderBlock, ProductVariantsRecommanderBlock);
    this.addModule(ComponentFactoryConstants.SitemapBlock, SitemapBlock);
    this.addModule(ComponentFactoryConstants.SitemapPage, SitemapPage);
    this.addModule(ComponentFactoryConstants.HTMLBlock, HTMLBlock);
    this.addModule(ComponentFactoryConstants.SpotlightExperienceBlock, SpotlightExperienceBlock);
    this.addModule(ComponentFactoryConstants.DoubleOptInVerificationBlock, DoubleOptInVerificationBlock)
    this.addModule(ComponentFactoryConstants.BreadCrumbs, BreadCrumbs);
    this.addModule(ComponentFactoryConstants.FAQBlock,FAQSearchComponent);
    this.addModule(ComponentFactoryConstants.CarouselBlock, CarouselBlock);
    this.addModule(ComponentFactoryConstants.DragSlider, DragSlider);
    this.addModule(ComponentFactoryConstants.AccordionBlock, AccordionBlock);
    this.addModule(ComponentFactoryConstants.SwitchTab, SwitchTab);
    this.addModule(ComponentFactoryConstants.MiniContentBlock, MiniContentBlock);
    this.addModule(ComponentFactoryConstants.DentistContacts, DentistContacts);
  }

  addModule(moduleName, moduleClass) {
    this.modules[moduleName.toUpperCase()] = moduleClass;
  }

  getModule(moduleClass) {
    const moduleName = moduleClass.toUpperCase();
    return this.modules[moduleName];
  }
}

export default new ComponentFactory();
